/**
 * constant.js
 *
 * This file includes all application-wide constant values. This may include
 * enums, reference IDs or data. For example:
 *
 * - data like the list of emails to show in various dropdowns
 * - account IDs for enabling specific functionality
 *
 * Specifically in __THIS__ file are constants specific to the Console
 * application UI. All API-related constants *should* be in in
 * `adready-api/constant`.
 */
import {
  LIS_DRAFT,
  LIS_NEW,
  LIS_PENDING_ACTIVATION,
  LIS_ACTIVATED,
  LIS_DISAPPROVED,
  LIS_CAMPAIGN_LIVE,
  LIS_PAUSED,
  LIS_ENDED,
  LIS_CANCELED,
  LIS_REOPENED,
  LIS_PENDING_CANCELLATION,
  LIS_PENDING_PAUSED,
  IOS_DRAFT,
  IOS_NEW,
  IOS_COMPLETED,
  IOS_LIVE,
  LIS_CHANGE_NEW,
  LIS_CHANGE_PENDING_ACTIVATION,
} from 'adready-api/constant';

export const DISPLAY_STATUS_NAME_DRAFT = 'Draft';
export const DISPLAY_STATUS_NAME_SUBMITTED = 'Submitted';
export const DISPLAY_STATUS_NAME_LIVE = 'Live';
export const DISPLAY_STATUS_NAME_PAUSED = 'Paused';
export const DISPLAY_STATUS_NAME_ENDED = 'Ended';
export const DISPLAY_STATUS_NAME_CANCELED = 'Canceled';
export const ENABLE_SEGMENT_TOOL = true;

export const FLIP_FILTER_CACHE_KEY = 'filters_cache';

export const DISPLAY_STATUS_NAMES_MAP = {
  [IOS_DRAFT]: DISPLAY_STATUS_NAME_DRAFT,
  [IOS_NEW]: DISPLAY_STATUS_NAME_SUBMITTED,
  [IOS_LIVE]: DISPLAY_STATUS_NAME_LIVE,
  [IOS_COMPLETED]: DISPLAY_STATUS_NAME_ENDED,
  [LIS_DRAFT]: DISPLAY_STATUS_NAME_DRAFT,
  [LIS_NEW]: DISPLAY_STATUS_NAME_SUBMITTED,
  [LIS_PENDING_ACTIVATION]: DISPLAY_STATUS_NAME_SUBMITTED,
  [LIS_ACTIVATED]: DISPLAY_STATUS_NAME_SUBMITTED,
  [LIS_DISAPPROVED]: DISPLAY_STATUS_NAME_SUBMITTED,
  [LIS_REOPENED]: DISPLAY_STATUS_NAME_SUBMITTED,
  [LIS_CAMPAIGN_LIVE]: DISPLAY_STATUS_NAME_LIVE,
  [LIS_PAUSED]: DISPLAY_STATUS_NAME_PAUSED,
  [LIS_ENDED]: DISPLAY_STATUS_NAME_ENDED,
  [LIS_CANCELED]: DISPLAY_STATUS_NAME_CANCELED,
  [LIS_PENDING_CANCELLATION]: DISPLAY_STATUS_NAME_CANCELED,
  [LIS_PENDING_PAUSED]: DISPLAY_STATUS_NAME_PAUSED,
};

// localStorage key name for accountId
export const KEY_ACCOUNT_ID = 'accountId';
export const KEY_ADVERTISER_ID = 'advertiserId';
export const DEMO_DOMAIN_PART = 'demo';
export const KEY_DEMO_ACCOUNT_ID = 'demoSelectedAccountId';
export const KEY_DEMO_ADVERTISER_ID = 'demoSelectedAdvertiserId';

export const VISITS = 'visits';
export const CURRENCY = 'CURRENCY';
export const PERCENTAGE = 'PERCENTAGE';
export const DECIMAL_PERCENTAGE = 'DECIMAL_PERCENTAGE';
export const FREQUENCY = 'FREQUENCY';
export const DECIMAL = 'DECIMAL';

export const DEFAULT_BRAND_COLOR = '#006987';

export const COLOR_PATTERN = [
  '#00abb7',
  '#5c80bc',
  '#4d5061',
  '#4e4b45',
  '#e7e247',
  '#ed474a',
  '#af5d63',
  '#846075',
  '#4a6c6f',
  '#503d3f',
  '#e9edde',
  '#e23d56',
  '#ffd151',
  '#ff8f3a',
  '#b7c776',
  '#716cde',
  '#775ec7',
  '#e85d52',
  '#ff6843',
  '#ffaa43',
  '#ffdd53',
  '#59c16a',
  '#42ad8f',
  '#23c5da',
  '#559595',
  '#528d9d',
  '#3d6c8a',
  '#415e85',
  '#415e85',
  '#544a74',
  '#564b77',
  '#58436a',
  '#664669',
  '#894968',
  '#d94d60',
];

export const DATE_FORMAT_MM_DD_YY = 'MM/DD/YY';
export const DEFAULT_DR_LOGO = '/assets/default/dr-logo-400x140.png';
export const SQUARE_DR_LOGO = '/assets/default/logos_dr-icon-90x90.png';
export const APP_ADREADY_ID = 1;
export const APP_FLIP_ID = 4;
export const APP_CONSOLE_ID = 5;
export const DEMO_APP_CONSOLE_ID = 6;
export const SEGMENT_SEARCH_ID = 7;
export const REPORT_BUILDER_2 = 8;
export const AI_ASSISTANT_REPORT_BUILDER = 9;
export const AI_ASSISTANT_FULL_PLATFORM = 10;

export const AI_ASSISTANT_PLATFORM_URL =
  'https://demo.digitalremedy.com/ai/assistant/v2/call-assistant.js';
export const AI_ASSISTANT_REPORT_BUILDER_URL =
  'https://demo.digitalremedy.com/ai/assistant/v2/call-assistant.js';
export const AI_ASSISTANT_MNI_LOGO_URL =
  'https://demo.digitalremedy.com/ai/assistant/v2/mni-logo.js';

export const ADVANCED_PERFORMANCE_SOLUTION_ID = 1;
export const PROGRAMMATIC_MEDIA_SOLUTION_ID = 2;
export const SEARCH_SOLUTION_ID = 3;
export const META_SOLUTION_ID = 4;
export const DOOH_SOLUTION_ID = 5;
export const YOUTUBE_SOLUTION_ID = 6;
export const CONVERGED_TV_SOLUTION_ID = 7;
export const MNI_MOTTO_SOLUTION_ID = 8;

export const RANGE_CUSTOM = 0;
export const RANGE_LAST_30_DAYS = 7;
export const RANGE_CAMPAIGN_TO_DATE = 13;
export const COMPARE_RANGE_PREV_DAY = 4;
export const COMPARE_RANGE_CUSTOM = 0;

export const CAMPAIGN_FILTER = 'campaign_filter';
export const ADGROUP_FILTER = 'adgroup_filter';
export const PIXEL_FILTER = 'pixel_filter';
export const DATE_FILTER = 'date_filter';
export const ADVANCED_FILTER = 'advanced_filter';
export const MEDIATYPE_FILTER = 'mediatype_filter';
export const CREATIVE_FILTER = 'creative_filter';
export const AUDIENCE_FILTER = 'audience_filter';
export const AD_FILTER = 'ad_filter';
export const KEYWORD_FILTER = 'keyword_filter';
export const CONVERSION_WINDOW_FILTER = 'conversion_window_filter';
export const MY_REPORTS = 'my_reports';
export const AGENCY_REPORTS = 'agency_reports';
export const SEARCH_DATA = 'search_data';
export const SEGMENTS_SEARCH = 'segments_search';
export const MAX_DAYS_TO_CONVERT = [
  { value: '1', checked: false },
  { value: '2', checked: false },
  { value: '3', checked: false },
  { value: '4', checked: false },
  { value: '5', checked: false },
  { value: '6', checked: false },
  { value: '7', checked: false },
  { value: '8', checked: false },
  { value: '9', checked: false },
  { value: '10', checked: false },
  { value: '11', checked: false },
  { value: '12', checked: false },
  { value: '13', checked: false },
  { value: '14', checked: false },
  { value: '15', checked: false },
  { value: '16', checked: false },
  { value: '17', checked: false },
  { value: '18', checked: false },
  { value: '19', checked: false },
  { value: '20', checked: false },
  { value: '21', checked: false },
  { value: '22', checked: false },
  { value: '23', checked: false },
  { value: '24', checked: false },
  { value: '25', checked: false },
  { value: '26', checked: false },
  { value: '27', checked: false },
  { value: '28', checked: false },
  { value: '29', checked: false },
  { value: '30', checked: false },
  { value: '31', checked: true },
];
export const MAX_DAYS_TO_CONVERT_JOURNEY = [
  { value: '1', checked: false },
  { value: '2', checked: false },
  { value: '3', checked: false },
  { value: '4', checked: false },
  { value: '5', checked: false },
  { value: '6', checked: false },
  { value: '7', checked: false },
  { value: '8', checked: false },
  { value: '9', checked: false },
  { value: '10', checked: false },
  { value: '11', checked: false },
  { value: '12', checked: false },
  { value: '13', checked: false },
  { value: '14', checked: false },
  { value: '15', checked: false },
  { value: '16', checked: false },
  { value: '17', checked: false },
  { value: '18', checked: false },
  { value: '19', checked: false },
  { value: '20', checked: false },
  { value: '21', checked: false },
  { value: '22', checked: false },
  { value: '23', checked: false },
  { value: '24', checked: false },
  { value: '25', checked: false },
  { value: '26', checked: false },
  { value: '27', checked: false },
  { value: '28', checked: false },
  { value: '29', checked: false },
  { value: '30', checked: false },
  { value: '31', checked: false },
  { value: '32', checked: false },
  { value: '33', checked: false },
  { value: '34', checked: false },
  { value: '35', checked: false },
  { value: '36', checked: false },
  { value: '37', checked: false },
  { value: '38', checked: false },
  { value: '39', checked: false },
  { value: '40', checked: false },
  { value: '41', checked: false },
  { value: '42', checked: false },
  { value: '43', checked: false },
  { value: '44', checked: false },
  { value: '45', checked: false },
  { value: '46', checked: false },
  { value: '47', checked: false },
  { value: '48', checked: false },
  { value: '49', checked: false },
  { value: '50', checked: false },
  { value: '51', checked: false },
  { value: '52', checked: false },
  { value: '53', checked: false },
  { value: '54', checked: false },
  { value: '55', checked: false },
  { value: '56', checked: false },
  { value: '57', checked: false },
  { value: '58', checked: false },
  { value: '59', checked: false },
  { value: '60', checked: false },
  { value: '61', checked: false },
  { value: '62', checked: false },
  { value: '63', checked: false },
  { value: '64', checked: false },
  { value: '65', checked: false },
  { value: '66', checked: false },
  { value: '67', checked: false },
  { value: '68', checked: false },
  { value: '69', checked: false },
  { value: '70', checked: false },
  { value: '71', checked: false },
  { value: '72', checked: false },
  { value: '73', checked: false },
  { value: '74', checked: false },
  { value: '75', checked: false },
  { value: '76', checked: false },
  { value: '77', checked: false },
  { value: '78', checked: false },
  { value: '79', checked: false },
  { value: '80', checked: false },
  { value: '81', checked: false },
  { value: '82', checked: false },
  { value: '83', checked: false },
  { value: '84', checked: false },
  { value: '85', checked: false },
  { value: '86', checked: false },
  { value: '87', checked: false },
  { value: '88', checked: false },
  { value: '89', checked: false },
  { value: '90', checked: true },
];

export const DEFAULT_CONVERSION_WINDOW = 31;

export const DEFAULT_MNI_ADVERTISER_TEMPLATE_MAPPING = 3;

export const DYNAMIC_PIXEL_CONVERSION_COLUMNS = [
  {
    key: 'pixel1_conversions',
    value: 'pixelName',
    align: 'right',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'pixel1_cpa',
    infinityKey: 'pixel1_cpaInfinity',
    value: 'pixelName CPA',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'pixel1_revenue',
    value: 'pixelName Revenue',
    align: 'right',
    formatter: { type: 'number', format: '$0.[00]' },
  },
  {
    key: 'pixel1_roas',
    infinityKey: 'pixel1_roasInfinity',
    value: 'pixelName ROAS',
    align: 'right',
    formatter: { type: 'percentage', format: '0.[0]%' },
  },
];

export const DATEPICKER_RANGE_OPTIONS = {
  '0': 'Custom',
  '1': 'Today',
  '2': 'Yesterday',
  '3': 'Last Week',
  '5': 'Last Month',
  '6': 'Last 7 Days',
  '7': 'Last 30 Days',
  '8': 'Last 60 Days',
  '9': 'Last 90 Days',
  '13': 'Campaign To Date',
  '14': 'Last 2 Weeks',
};
export const DATEPICKER_COMPARE_RANGE_OPTIONS = {
  '0': 'Custom',
  '1': 'Previous Period',
  '2': 'Previous Year',
  '3': 'Previous Month',
  '4': 'Previous Day',
};

// List of account ids for whom to hide inactive solutions
// Account Id 70 = VI Marketing & Branding
// Account Id 77 = Noble People
// Account Id 81 = Tinuiti
// Account Id 114 = Network Affiliates
// Account Id 125 = Ron Foth
// Account Id 182 = Veritas Media Group
// Account Id 191 = Compulse
// Account Id 202 = The Gate Worldwide
// Account Id 208 = Media.Monks
// Account Id 274 = Motivas
// Account Id 315 = Giant Spoon
// Account Id 334 = Media Brokers International
export const ACCOUNTS_TO_HIDE_INACTIVE_SOLUTIONS = [
  70,
  77,
  81,
  114,
  125,
  182,
  191,
  202,
  208,
  274,
  315,
  334,
  404,
];

export const SHOW_ONLY_SOLUTIONS_BY_ACCOUNT = [
  {
    accountId: 404, // acme
    isDemoAccount: true,
    showOnlySolutions: ['Advanced Performance'],
  },
  {
    accountId: 191, // compulse
    isDemoAccount: true,
    showOnlySolutions: ['Advanced Performance'],
  },
];

export const MNI_ACCOUNT_ID = 76; // mni motto - ancora education

export const MNI_DEMO_ACCOUNT_ID = 572; // mni motto demo account
export const DSP = [
  { id: 'XANDR', name: 'Xandr' },
  { id: 'YAHOO', name: 'Yahoo' },
];
export const VERTICALS = [
  { id: 'Arts & Entertainment', name: 'Arts & Entertainment' },
  { id: 'Automotive', name: 'Automotive' },
  { id: 'Business', name: 'Business' },
  { id: 'Careers', name: 'Careers' },
  { id: 'Education', name: 'Education' },
  { id: 'Family & Parenting', name: 'Family & Parenting' },
  { id: 'Health & Fitness', name: 'Health & Fitness' },
  { id: 'Food & Drink', name: 'Food & Drink' },
  { id: 'Hobbies & Interests', name: 'Hobbies & Interests' },
  { id: 'Home & Garden', name: 'Home & Garden' },
  { id: 'Law, Government & Politics', name: 'Law, Government & Politics' },
  { id: 'News', name: 'News' },
  { id: 'Personal Finance', name: 'Personal Finance' },
  { id: 'Society', name: 'Society' },
  { id: 'Science', name: 'Science' },
  { id: 'Pets', name: 'Pets' },
  { id: 'Sports', name: 'Sports' },
  { id: 'Style & Fashion', name: 'Style & Fashion' },
  { id: 'Technology & Computing', name: 'Technology & Computing' },
  { id: 'Travel', name: 'Travel' },
  { id: 'Real Estate', name: 'Real Estate' },
  { id: 'Shopping', name: 'Shopping' },
  { id: 'Religion & Spirituality', name: 'Religion & Spirituality' },
  { id: 'Uncategorized', name: 'Uncategorized' },
  { id: 'Non-Standard Content', name: 'Non-Standard Content' },
  { id: 'Illegal Content', name: 'Illegal Content' },
];
export const VIA_ACCOUNT_ID = 73; // VIA Media 2021

export const MNI_REPORTING_TABLEAU_URL = '/views/MNITemplate-CampaignIDFilter';

export const MNI_OVERVIEW_TAB_OPTIONS = [
  {
    id: 'overview',
    value: 'Overview',
    viewUrl: '/views/MNITemplate-CampaignIDFilter',
    viewSubUrl: '/OverviewTemplate',
    dataUrl:
      'https://dev-tableau.digitalremedy.com/api/3.6/sites/e5a6f961-1bb5-4f47-a077-050c85df981f/views/4ec1f9d6-ebeb-41ac-bf82-b09b3bdb57b1/data',
    demoViewUrl: '/views/DemoMNITemplate-CampaignIDFilter',
    demoViewSubUrl: '/Overview',
    selected: true,
  },
  {
    id: 'distribution',
    value: 'Distribution',
    viewUrl: '/views/MNITemplate-CampaignIDFilter',
    viewSubUrl: '/DistributionTemplate',
    dataUrl:
      'https://dev-tableau.digitalremedy.com/api/3.6/sites/e5a6f961-1bb5-4f47-a077-050c85df981f/views/b0de8e39-5832-4078-9351-5474ae69851b/data',

    demoViewUrl: '/views/DemoMNITemplate-CampaignIDFilter',
    // demoViewSubUrl: '/DistributionTemplate',
    demoViewSubUrl: '/Distribution',
  },
  {
    id: 'journeyanalytics',
    value: 'Journey Analytics',
    viewUrl: '/views/MNIMottoMediaTypeJourneyDashboard/MediaTypeJourneyViz',
    demoViewUrl: '/views/MNIMottoMediaTypeJourneyDashboard/MediaTypeJourneyViz',
  },
];
export const VIA_MEDIA_CLIENT_NAME_FOR_API = 'VIA Media 2021';
export const EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_VALIDATION_MSG = 'Please enter valid email id';
export const TEMPLATE_ID_ENABLED_FOR_ACCOUNTS = [76, 311, 572];

export const DISTRIBUTION_DIMENSION_TITLES = {
  venueType: 'Venue Type',
};

export const METRIC_DISTRIBUTION_DIMENSION_TITLES = {
  venueType: 'Venue Type',
};

// labelConfig is a constants json for label values such as header and input lable names for account specific
// ** NOTE: always add default value before adding account specific values
export const labelConfig = {
  topFilterSection: {
    defaultLabel: {
      campaigns: 'Campaigns',
      adGroup: 'Ad Group',
      Audience: 'Audience',
      conversions: 'Conversions',
    },
    accountIdSpecificLabel: {
      73: {
        campaigns: 'Contract ID',
        adGroup: 'ConLine ID',
        Audience: 'Tier',
      },
    },
  },
  advFilterSection: {
    defaultLabel: {
      campaigns: 'Campaigns',
      adGroup: 'Ad Group',
      audianceFilter: 'Filter by Audience',
    },
    accountIdSpecificLabel: {
      73: {
        campaigns: 'Contract ID',
        adGroup: 'ConLine ID',
        audianceFilter: 'Filter by Tier',
      },
      76: {
        audianceFilter: 'Filter by Placements',
      },
    },
  },
};

// FROM BLIP
export const DEFAULT_CONVERSION_WINDOW_DISPLAY = 31;
export const DEFAULT_CONVERSION_WINDOW_UNIT = 'DAYS';
export const DEFAULT_METHODOLOGY = 'last_touch';
export const UNCATEGORIZED_EVENT_CATEGORY = 'default';
export const FLIP_SELECTED_MENU_PAGE = 'FLIP_SELECTED_MENU_PAGE';
export const FLIP_PARAM_CACHE_KEY = 'filterCacheId';
export const TAR_COUNTRY = 8;
export const TAR_STATE = 10;
export const TAR_DMA = 9;
export const TAR_CITY = 24;
export const TAR_ZIP_CODES = 12;
export const TAR_PUBLISHER_FLIP_OTT_TARGETING = 40;
export const DEFAULT_LOGO_URL = '/assets/flip-nondr-logo.png';
export const DEFAULT_LOGIN_IMG = '/assets/flip-login-sq.jpg';
// constants name for dropdown in chart comps
export const IMPRESSIONS = 'Impressions';
export const SPEND = 'Spend';
export const HOMEPAGE_VIEWS = 'Homepage Views';
export const CHECKOUTS = 'Checkouts';
export const LOC_VISITS = 'Location Visits';
export const REVENUE = 'Revenue';
export const ROAS = 'ROAS';
export const CPA = 'CPA';
export const VCR = 'VCR';
export const CTR = 'CTR';
export const VIDEO_COMPLETION = 'Video Completions';
export const INCREMENTAL_REACH = 'Incremental Reach';
export const CLICKS = 'Clicks';
export const VIEWS = 'views';
export const HOMEPAGE = 'Homepage';

export const KIND_VIEWS = 'views';
export const KIND_SPEND = 'spend';
export const KIND_CPM = 'cpm';
export const KIND_EVENT = 'event';
export const KIND_METRIC = 'metric';
export const USE_PG_API_FOR_REPORT_BUILDER = false;

export const ACCOUNTS_TO_SHOW = [476];

export const DAILY_VIEW_KIND_OPTIONS = [
  { id: KIND_VIEWS, name: 'Impressions' },
  { id: KIND_SPEND, name: 'Spend' },
  { id: KIND_EVENT, name: 'Event' },
  { id: KIND_METRIC, name: 'Metric' },
];
export const DAILY_VIEW_NEW_KIND_OPTIONS = [
  { id: KIND_VIEWS, name: 'Impressions' },
  { id: KIND_SPEND, name: 'Spend' },
  { id: KIND_CPM, name: 'CPM' },
  { id: KIND_EVENT, name: 'Event' },
  { id: KIND_METRIC, name: 'Metric' },
];
export const DISTRIBUTION_KIND_OPTIONS = [
  { id: KIND_VIEWS, name: 'Impressions' },
  { id: KIND_SPEND, name: 'Spend' },
  { id: KIND_EVENT, name: 'Event' },
];

export const DISTRIBUTION_GROUP_BY_TIMES_EXPOSED = { id: 'timesexposed', name: 'Times Exposed' };
export const DISTRIBUTION_GROUP_BY_ADDRESSES = { id: 'subcategories', name: 'Address' };

export const DISTRIBUTION_GROUP_BY_OPTIONS_VCR = [
  { id: 'mediatype', name: 'Media Type' },
  { id: 'creative', name: 'Creative' },
  { id: 'publisher', name: 'Publisher' },
  { id: 'channel', name: 'Channel' },
  { id: 'audience', name: 'Audience' },
  { id: 'adgroup', name: 'Ad Group' },
];

export const DISTRIBUTION_GROUP_BY_OPTIONS_CTR = [
  ...DISTRIBUTION_GROUP_BY_OPTIONS_VCR,
  { id: 'device', name: 'Device Type' },
  { id: 'state', name: 'State' },
  { id: 'dma', name: 'DMA' },
];

export const DISTRIBUTION_GROUP_BY_OPTIONS = [
  ...DISTRIBUTION_GROUP_BY_OPTIONS_CTR,
  { id: 'daypart', name: 'Daypart (EST)' },
];

export const REPORT_OPTIONS = [
  { id: 'overall', name: 'Overall', type: 'performance' },
  { id: 'mediatype', name: 'Media Type', type: 'performance' },
  { id: 'creative', name: 'Creative', type: 'performance' },
  { id: 'publisher', name: 'Publisher', type: 'performance' },
  { id: 'channel', name: 'Channel', type: 'performance' },
  { id: 'audience', name: 'Audience', type: 'performance' },
  { id: 'adgroup', name: 'Ad Group', type: 'performance' },
  { id: 'device', name: 'Device Type', type: 'performance' },
  { id: 'state', name: 'State', type: 'performance' },
  { id: 'dma', name: 'DMA', type: 'performance' },
  { id: 'zip', name: 'Zip Code', type: 'performance' },
  { id: 'day', name: 'Day', type: 'performance' },
  { id: 'daypart', name: 'Daypart (EST)', type: 'performance' },
];

export const ERROR_MESSAGE_FETCH_TEMPLATE = 'Unable to Fetch Templates';
export const ERROR_MESSAGE_DELIVERY_SELECTION = 'Please select an option under Delivery';
export const ERROR_MESSAGE_NAME_REQUIRED =
  'Please provide a valid report name (cannot be empty or spaces only)';

export const NEW_REPORT_OPTIONS = [
  { id: 'overall', name: 'Overall', type: 'performance' },
  { id: 'mediatype', name: 'Media Type', type: 'performance' },
  { id: 'creative', name: 'Creative', type: 'performance' },
  { id: 'publisher', name: 'Publisher', type: 'performance' },
  { id: 'channel', name: 'Channel', type: 'performance' },
  { id: 'audience', name: 'Audience', type: 'performance' },
  { id: 'adgroup', name: 'Ad Group', type: 'performance' },
  { id: 'device', name: 'Device Type', type: 'performance' },
  { id: 'state', name: 'State', type: 'performance' },
  { id: 'dma', name: 'DMA', type: 'performance' },
  { id: 'zip', name: 'Zip Code', type: 'performance' },
];

export const NEW_REPORT_BUILDER_METRIC_OPTIONS = [
  {
    key: 'impressions',
    name: 'Imp',
    value: 'Impressions',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'spend',
    name: '($)Spend',
    value: 'Spend',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'cpm',
    name: '($)CPM',
    value: 'CPM',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'checkouts',
    name: 'Visits',
    value: 'Visits',
    width: '95px',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  // {
  //   key: 'conversionRate',
  //   name: '(%)Conversion Rate',
  //   value: 'Conversion Rate',
  //   formatter: { type: 'number', format: '0.00' },
  // },
  {
    key: 'lead',
    name: 'Leads',
    value: 'Leads',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'leadConversionRate',
    name: '(%)Lead Conversion Rate',
    value: 'Lead Conversion Rate',
    formatter: { type: 'number', format: '0.00' },
  },
  {
    key: 'leadTimeToConvert',
    name: 'Leads Time to Convert',
    value: 'Leads Time to Convert',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'leadTotalExposures',
    name: 'Leads Total Exposures',
    value: 'Leads Total Exposures',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'checkoutTimeToConvert',
    name: 'Checkouts Time to Convert',
    value: 'Checkouts Time to Convert',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'checkoutTotalExposures',
    name: 'Checkouts Total Exposures',
    value: 'Checkouts Total Exposures',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'checkout',
    name: 'Checkouts',
    value: 'Checkouts',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'checkoutConversionRate',
    name: '(%)Checkout Conversion Rate',
    value: 'Checkout Conversion Rate',
    formatter: { type: 'number', format: '0.00' },
  },
  {
    key: 'pageVisitTimeToConvert',
    name: 'Page Visits Time to Convert',
    value: 'Page Visits Time to Convert',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'locationVisitTimeToConvert',
    name: 'Location Visits Time to Convert',
    value: 'Location Visits Time to Convert',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'activityTimeToConvert',
    name: 'Activities Time to Convert',
    value: 'Activities Time to Convert',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'pageVisitTotalExposures',
    name: 'Page Visits Total Exposures',
    value: 'Page Visits Total Exposures',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'locationVisitTotalExposures',
    name: 'Location Visits Total Exposures',
    value: 'Location Visits Total Exposures',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'activityTotalExposures',
    name: 'Activities Total Exposures',
    value: 'Activities Total Exposures',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'pageVisit',
    name: 'Page Visits',
    value: 'Page Visits',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'locationVisit',
    name: 'Location Visits',
    value: 'Location Visits',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'activity',
    name: 'Activities',
    value: 'Activities',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'pageVisitConversionRate',
    name: '(%)Page Visits Conversion Rate',
    value: 'Page Visits Conversion Rate',
    formatter: { type: 'number', format: '0.00' },
  },
  {
    key: 'locationVisitConversionRate',
    name: '(%)Location Visits Conversion Rate',
    value: 'Location Visits Conversion Rate',
    formatter: { type: 'number', format: '0.00' },
  },
  {
    key: 'activityConversionRate',
    name: '(%)Activities Conversion Rate',
    value: 'Activities Conversion Rate',
    formatter: { type: 'number', format: '0.00' },
  },
  {
    key: USE_PG_API_FOR_REPORT_BUILDER ? 'videoCompletionRate' : 'vcr',
    name: '(%)Completion Rate',
    value: 'Completion Rate',
    width: '165px',
    formatter: { type: 'number', format: '0' },
  },
  {
    key: USE_PG_API_FOR_REPORT_BUILDER ? 'videoCompletions' : 'completions',
    name: 'Completions',
    value: 'Completions',
    width: '125px',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: USE_PG_API_FOR_REPORT_BUILDER ? 'clickThroughRate' : 'ctr',
    name: '(%)CTR',
    value: 'CTR',
    formatter: { type: 'number', format: '0.00' },
  },
  {
    key: 'clicks',
    name: 'Clicks',
    value: 'Clicks',
    formatter: { type: 'number', format: '0,0' },
  },
  {
    key: 'metric',
    name: 'metric',
    value: 'metric',
    formatter: { type: 'number', format: '0' },
  },
  {
    key: 'incrementalReach',
    name: 'Inc. Reach',
    value: 'Inc. Reach',
    formatter: { type: 'number', format: '0' },
  },
  // {
  //   key: 'pm',
  //   name: 'Performance Multiplier',
  //   value: 'Performance Multiplier',
  //   formatter: { type: 'number', format: '0,0.[00]' },
  // },
  {
    key: 'leadPm',
    name: 'Leads Performance Multiplier',
    value: 'Leads Performance Multiplier',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'checkoutPm',
    name: 'Checkout Performance Multiplier',
    value: 'Checkout Performance Multiplier',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'pageVisitPm',
    name: 'Page Visits Performance Multiplier',
    value: 'Page Visits Performance Multiplier',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'locationVisitPm',
    name: 'Location Visits Performance Multiplier',
    value: 'Location Visits Performance Multiplier',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'activityPm',
    name: 'Activities Performance Multiplier',
    value: 'Activities Performance Multiplier',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'reach',
    name: 'Reach',
    value: 'Reach',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'frequency',
    name: 'Frequency',
    value: 'Frequency',
    formatter: { type: 'number', format: '0.[00]' },
  },
  {
    key: 'leadCpa',
    name: 'Lead CPA',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'checkoutCpa',
    name: 'Checkout CPA',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'pageVisitCpa',
    name: 'Page Visit CPA',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'locationVisitCpa',
    name: 'Location Visit CPA',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'activityCpa',
    name: 'Activities CPA',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'revenue',
    name: '($)Rev',
    value: 'Revenue',
    formatter: { type: 'number', format: '0,0.00' },
  },
  {
    key: 'roas',
    name: '(%)ROAS',
    value: 'ROAS',
    formatter: { type: 'number', format: '0' },
  },
  {
    key: 'day',
    name: 'Day',
    value: 'Day',
    formatter: { type: 'datetime', format: 'M/D/YYYY' },
  },
  {
    key: 'dayPart',
    name: 'Hour',
    value: 'Hour',
  },
];

export const REPORTS_NEW_BUILDER_METRIC_OPTIONS = {
  rollup: [
    {
      key: 'uniqueId',
      name: 'Unique ID',
      value: 'Unique ID',
    },
    {
      key: 'ip',
      name: 'Hashed IP Address',
      value: 'Hashed IP Address',
      width: '105px',
    },
    {
      key: 'revenue',
      name: '($)Rev',
      value: '($)Rev',
      formatter: { type: 'number', format: '0,0.00' },
    },
    {
      key: 'subCategory',
      name: 'Sub Category',
      value: 'Sub Category',
    },
    {
      key: 'pixelTimestamp',
      name: 'Conversion Timestamp',
      value: 'Conversion Timestamp',
      width: '110px',
      formatter: { type: 'datetime', format: 'MM/DD/YYYY HH:mm' },
      stickyColumnAnchor: true,
    },
    {
      key: 'creative',
      name: 'Creative',
      value: 'Creative',
      width: '260px',
    },
    {
      key: 'publisher',
      name: 'Publisher',
      value: 'Publisher',
      width: '140px',
    },
    {
      key: 'channel',
      name: 'Channel',
      value: 'Channel',
      width: '140px',
    },
    {
      key: 'audience',
      name: 'Audience/ Tactic',
      value: 'Audience/ Tactic',
      width: '110px', // 120
    },
    {
      key: 'mediaType',
      name: 'Media Type',
      value: 'Media Type',
      width: '100px',
    },
    {
      key: 'state',
      name: 'State',
      value: 'State',
      width: '50px', // 80
    },
    {
      key: 'dma',
      name: 'DMA',
      value: 'DMA',
      width: '105px', // 140
    },
    {
      key: 'deviceType',
      name: 'Device Type',
      value: 'Device Type',
      width: '100px',
    },
    {
      key: 'impressionTimestamp',
      name: 'Imp Timestamp',
      value: 'Imp Timestamp',
      width: '105px',
      formatter: { type: 'datetime', format: 'MM/DD/YYYY HH:mm' },
    },
    {
      key: 'timeToConvert',
      name: 'Time to Convert (Hrs)',
      value: 'Time to Convert',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: 'timesExposed',
      name: 'Times Exposed',
      value: 'Times Exposed',
      formatter: { type: 'number', format: '0' },
    },
  ],
  performance: [
    {
      key: 'ep',
      name: 'All Events and Performance',
      value: 'All Events and Performance',
      checked: false,
    },
    {
      key: 'impressions',
      name: 'Imp',
      value: 'Impressions',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: 'spend',
      name: '($)Spend',
      value: 'Spend',
      formatter: { type: 'number', format: '0,0.00' },
    },
    {
      key: 'cpm',
      name: '($)CPM',
      value: 'CPM',
      formatter: { type: 'number', format: '0,0.00' },
    },
    {
      key: 'checkouts',
      name: 'Visits',
      value: 'Visits',
      width: '95px',
      formatter: { type: 'number', format: '0,0.[00]' },
    },
    // {
    //   key: 'lead',
    //   name: 'Leads',
    //   value: 'Leads',
    //   formatter: { type: 'number', format: '0,0.[00]' },
    // },
    // {
    //   key: 'conversionRate',
    //   name: '(%)Conversion Rate',
    //   value: 'Conversion Rate',
    //   formatter: { type: 'number', format: '0.00' },
    // },
    {
      key: USE_PG_API_FOR_REPORT_BUILDER ? 'videoCompletionRate' : 'vcr',
      name: '(%)Completion Rate',
      value: 'Completion Rate',
      width: '165px',
      formatter: { type: 'number', format: '0' },
    },
    {
      key: USE_PG_API_FOR_REPORT_BUILDER ? 'videoCompletions' : 'completions',
      name: 'Completions',
      value: 'Completions',
      width: '125px',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: USE_PG_API_FOR_REPORT_BUILDER ? 'clickThroughRate' : 'ctr',
      name: '(%)CTR',
      value: 'CTR',
      formatter: { type: 'number', format: '0.00' },
    },
    {
      key: 'clicks',
      name: 'Clicks',
      value: 'Clicks',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: 'metric',
      name: 'metric',
      value: 'metric',
      formatter: { type: 'number', format: '0' },
    },
    {
      key: 'incrementalReach',
      name: 'Inc. Reach',
      value: 'Inc. Reach',
      formatter: { type: 'number', format: '0' },
    },
    // {
    //   key: 'pm',
    //   name: 'Performance Multiplier',
    //   value: 'Performance Multiplier',
    //   formatter: { type: 'number', format: '0,0.[00]' },
    // },
    {
      key: 'reach',
      name: 'Reach',
      value: 'Reach',
      formatter: { type: 'number', format: '0,0.[00]' },
    },
    {
      key: 'frequency',
      name: 'Frequency',
      value: 'Frequency',
      formatter: { type: 'number', format: '0.[00]' },
    },
  ],
};

export const REPORT_BUILDER_METRIC_OPTIONS = {
  rollup: [
    {
      key: 'uniqueId',
      name: 'Unique ID',
      value: 'Unique ID',
    },
    {
      key: 'ip',
      name: 'Hashed IP Address',
      value: 'Hashed IP Address',
      width: '105px',
    },
    {
      key: 'revenue',
      name: '($)Rev',
      value: '($)Rev',
      formatter: { type: 'number', format: '0,0.00' },
    },
    {
      key: 'subCategory',
      name: 'Sub Category',
      value: 'Sub Category',
    },
    {
      key: 'pixelTimestamp',
      name: 'Conversion Timestamp',
      value: 'Conversion Timestamp',
      width: '110px',
      formatter: { type: 'datetime', format: 'MM/DD/YYYY HH:mm' },
      stickyColumnAnchor: true,
    },
    {
      key: 'creative',
      name: 'Creative',
      value: 'Creative',
      width: '260px',
    },
    {
      key: 'publisher',
      name: 'Publisher',
      value: 'Publisher',
      width: '140px',
    },
    {
      key: 'channel',
      name: 'Channel',
      value: 'Channel',
      width: '140px',
    },
    {
      key: 'audience',
      name: 'Audience/ Tactic',
      value: 'Audience/ Tactic',
      width: '110px', // 120
    },
    {
      key: 'mediaType',
      name: 'Media Type',
      value: 'Media Type',
      width: '100px',
    },
    {
      key: 'state',
      name: 'State',
      value: 'State',
      width: '50px', // 80
    },
    {
      key: 'dma',
      name: 'DMA',
      value: 'DMA',
      width: '105px', // 140
    },
    {
      key: 'deviceType',
      name: 'Device Type',
      value: 'Device Type',
      width: '100px',
    },
    {
      key: 'impressionTimestamp',
      name: 'Imp Timestamp',
      value: 'Imp Timestamp',
      width: '105px',
      formatter: { type: 'datetime', format: 'MM/DD/YYYY HH:mm' },
    },
    {
      key: 'timeToConvert',
      name: 'Time to Convert (Hrs)',
      value: 'Time to Convert',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: 'timesExposed',
      name: 'Times Exposed',
      value: 'Times Exposed',
      formatter: { type: 'number', format: '0' },
    },
  ],
  performance: [
    {
      key: 'ep',
      name: 'All Events and Performance',
      value: 'All Events and Performance',
      checked: false,
    },
    {
      key: 'impressions',
      name: 'Imp',
      value: 'Impressions',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: 'spend',
      name: '($)Spend',
      value: 'Spend',
      formatter: { type: 'number', format: '0,0.00' },
    },
    {
      key: 'cpm',
      name: '($)CPM',
      value: 'CPM',
      formatter: { type: 'number', format: '0,0.00' },
    },
    {
      key: 'checkouts',
      name: 'Visits',
      value: 'Visits',
      width: '95px',
      formatter: { type: 'number', format: '0,0.[00]' },
    },
    {
      key: 'conversionRate',
      name: '(%)Conversion Rate',
      value: 'Conversion Rate',
      formatter: { type: 'number', format: '0.00' },
    },
    {
      key: USE_PG_API_FOR_REPORT_BUILDER ? 'videoCompletionRate' : 'vcr',
      name: '(%)Completion Rate',
      value: 'Completion Rate',
      width: '165px',
      formatter: { type: 'number', format: '0' },
    },
    {
      key: USE_PG_API_FOR_REPORT_BUILDER ? 'videoCompletions' : 'completions',
      name: 'Completions',
      value: 'Completions',
      width: '125px',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: USE_PG_API_FOR_REPORT_BUILDER ? 'clickThroughRate' : 'ctr',
      name: '(%)CTR',
      value: 'CTR',
      formatter: { type: 'number', format: '0.00' },
    },
    {
      key: 'clicks',
      name: 'Clicks',
      value: 'Clicks',
      formatter: { type: 'number', format: '0,0' },
    },
    {
      key: 'metric',
      name: 'metric',
      value: 'metric',
      formatter: { type: 'number', format: '0' },
    },
    {
      key: 'incrementalReach',
      name: 'Inc. Reach',
      value: 'Inc. Reach',
      formatter: { type: 'number', format: '0' },
    },
    {
      key: 'pm',
      name: 'Performance Multiplier',
      value: 'Performance Multiplier',
      formatter: { type: 'number', format: '0,0.[00]' },
    },
    {
      key: 'reach',
      name: 'Reach',
      value: 'Reach',
      formatter: { type: 'number', format: '0,0.[00]' },
    },
    {
      key: 'frequency',
      name: 'Frequency',
      value: 'Frequency',
      formatter: { type: 'number', format: '0.[00]' },
    },
  ],
};
export const REPORT_BUILDER_EXTRA_METRICS = [
  {
    key: 'timeToConvert',
    name: 'Time to Convert (Hrs)',
    value: 'Time to Convert',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
  {
    key: 'totalExposures',
    name: 'Total Exposures',
    value: 'Total Exposures',
    formatter: { type: 'number', format: '0,0.[00]' },
  },
];

export const REPORT_BUILDER_DISABLED_METRICS = {
  daypart: ['videoCompletionRate', 'videoCompletions', 'vcr', 'completions'],
  // device: ['videoCompletionRate', 'videoCompletions', 'vcr', 'completions'],
  state: ['videoCompletionRate', 'videoCompletions', 'vcr', 'completions'],
  // dma: ['videoCompletionRate', 'videoCompletions', 'vcr', 'completions'],
  day: ['videoCompletionRate', 'videoCompletions', 'vcr', 'completions'],
  zipcode: ['videoCompletionRate', 'videoCompletions', 'vcr', 'completions'],
};

export const INCREMENTALITY_GROUP_BY_OPTIONS = [
  { id: 'overall', name: 'Overall Lift' },
  { id: 'creative', name: 'Creative' },
  { id: 'publisher', name: 'Publisher' },
  { id: 'audience', name: 'Audience' },
  { id: 'mediatype', name: 'Media Type' },
  { id: 'adgroup', name: 'Ad Group' },
];

export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const WINDOW_TYPE_IMPRESSION = 'IMPRESSION';
export const WINDOW_TYPE_REACH = 'REACH';
export const WINDOW_TYPE_CONVERSION = 'CONVERSION';
export const WINDOW_TYPE_LIFT = 'LIFT';

export const ENUM_IS_WATCH_STATS = {
  IMPRESSION: false,
  REACH: false,
  CONVERSION: false,
};
// left menu window type
export const WINDOW_TYPE_SUMMARY = 'SUMMARY';
export const WINDOW_TYPE_CREATIVE = 'CREATIVE';
export const WINDOW_TYPE_PUBLISHER = 'PUBLISHER';
export const WINDOW_TYPE_AUDIENCE = 'AUDIENCE';
export const WINDOW_TYPE_CHANNEL = 'CHANNEL';
export const WINDOW_TYPE_MEDIA_TYPE = 'MEDIA TYPE';
export const WINDOW_TYPE_AD_GROUP = 'AD GROUP';

export const LEFT_PANE_WINDOW_TYPES = [
  WINDOW_TYPE_SUMMARY,
  WINDOW_TYPE_CREATIVE,
  WINDOW_TYPE_PUBLISHER,
  WINDOW_TYPE_AUDIENCE,
  WINDOW_TYPE_CHANNEL,
  WINDOW_TYPE_MEDIA_TYPE,
  WINDOW_TYPE_AD_GROUP,
];

// right menu window type
export const WINDOW_TYPE_DAILY_VIEW = 'DAILY_VIEW';
export const WINDOW_TYPE_DISTRIBUTION = 'DISTRIBUTION';
export const WINDOW_TYPE_INCREMENTALITY = 'INCREMENTALITY';
export const WINDOW_TYPE_HALO_EFFECT = 'HALO_EFFECT';
export const WINDOW_TYPE_BRAND_LIFT = 'BRAND_LIFT';

export const APP_ADREADY_MNI_ID = 3;

export const RIGHT_PANE_WINDOW_TYPES = [WINDOW_TYPE_DAILY_VIEW, WINDOW_TYPE_DISTRIBUTION];

export const USER_PROVIDER_OPTIONS = ['NONE', 'GOOGLE', 'LOCAL', 'DR_SSO'];

export const ROLE_ID_INTERNAL_SUB_ADMIN = 10;
export const ROLE_ID_INTERNAL_USER = 11;
export const ROLE_ID_EXTERNAL_USER = 13;
export const REPORT_BUILDER_TITLE = 'Report Builder';

export const LAYOUT_TYPES = {
  DASHBOARD: 'DASHBOARD',
  BL_CAMPAIGN_SETUP: 'BL_CAMPAIGN_SETUP',
  FLIPPIXEL: 'FLIPPIXEL',
  FLIP_PIXEL_VERIFICATION: 'LIP_PIXEL_VERIFICATION',
  CAMPAIGNS: 'CAMPAIGNS',
  NEW_CAMPAIGNS: 'NEW_CAMPAIGNS',
  ADMIN: 'ADMIN',
  ACCOUNT_SETUP: 'ACCOUNT_SETUP',
  REPORT_BUILDER: 'REPORT_BUILDER',
};

export const LI_STATUS_COLOR_CODES = {
  '1': '#d6dadf',
  '2': '#9cc8e4',
  '3': '#4fd1cf',
  '4': '#4fd19b',
  '5': '#006987',
  '7': '#fdce07',
  '8': '#e6575d',
  '9': '#3d9afb',
  '10': '#fd9807',
  '23': '#efd1c4',
  '24': '#efe3c4',
};

export const NEW_CAMPAIGN_PAGES = {
  CAMPAIGN_INFO: 'campaign_info',
  BRAND_SAFETY: 'brand_safety',
  LUCID: 'lucid',
  AD_GROUP_INFO: 'ad_group_info',
  AD_GROUP_TARGETING: 'ad_group_targeting',
  AD_GROUP_ADS: 'ad_group_ads',
  REVIEW_CAMPAIGN: 'review_campaign',
};
// pagination constants
export const PAGINATION_DEFAULT_ITEM_PER_PAGE = 25;
export const PAGINATION_OPTIONS = [25, 50, 100, 200];
export const FLIP_CAMPAIGN_GOAL_CPA_METRIC = { id: 1, label: 'CPA' };
export const FLIP_CAMPAIGN_GOAL_ROAS_METRIC = { id: 2, label: 'ROAS' };
export const FLIP_CAMPAIGN_GOAL_VCR_METRIC = { id: 3, label: 'VCR' };
export const FLIP_CAMPAIGN_GOAL_REACH_METRIC = { id: 4, label: 'REACH' };

// pixel sub pages
export const FLIP_PIXEL_SUBPAGE = 'flip_pixel_subpage';
export const FLIP_PIXEL_SUBPAGE_CODE = 'code';
export const FLIP_PIXEL_SUBPAGE_VERIFY = 'verify';

export const FLIP_CAMPAIGN_GOAL_METRICS = [
  FLIP_CAMPAIGN_GOAL_CPA_METRIC,
  FLIP_CAMPAIGN_GOAL_ROAS_METRIC,
];

export const FLIP_CAMPAIGN_AWARENESS_METRICS = [
  FLIP_CAMPAIGN_GOAL_VCR_METRIC,
  FLIP_CAMPAIGN_GOAL_REACH_METRIC,
];
export const FLIP_CAMPAIGN_INFO_DEFAULT = {
  goal: {
    name: 'Awareness',
    category: 'vcr',
    isDisabled: false,
    isActive: true,
    subCategories: [],
    metric: FLIP_CAMPAIGN_GOAL_VCR_METRIC,
    defaultMetric: 'VCR',
  },
  methodology: {
    id: 1,
    value: 'last touch',
  },
  optimization: {
    weightage: {
      id: 111,
      value: 'direct',
    },
    attribution: {
      id: 1,
      value: 'Full Conversions',
    },
    impressions: {
      id: 11,
      value: 'total',
    },
  },
  conversionWindow: {
    unit: {
      id: 1,
      value: 'days',
    },
    value: 31,
  },
};
export const LUCID_CAMPAIGN_INFO_DEFAULT = {
  brandLiftInfo: {
    category: null,
    expectedImpressionCount: null,
    type: { id: 1, value: 'products' },
    competitors: [],
  },
};
export const CAMPAIGN_NAV_BAR_OPTIONS = [
  {
    title: 'Campaign Detail',
    leftIcon: 'star',
    rightIcon: 'check',
    pageName: NEW_CAMPAIGN_PAGES.CAMPAIGN_INFO,
    pageIndex: 1,
  },
  {
    title: 'Brand Safety & Verification',
    leftIcon: 'shield-check',
    rightIcon: 'check',
    pageName: NEW_CAMPAIGN_PAGES.BRAND_SAFETY,
    pageIndex: 2,
  },
  {
    title: 'Brand Lift Information',
    leftIcon: 'chart-line',
    rightIcon: 'check',
    pageName: NEW_CAMPAIGN_PAGES.LUCID,
    pageIndex: 3,
    disabled: true,
  },
];
export const ACC_SETUP_NAV_BAR_OPTIONS = [
  {
    id: 'ACCOUNT_INFO',
    title: 'Account Info',
    leftIcon: 'user',
    rightIcon: 'check',
    isActive: false,
    route: 'ACCOUNT_SETUP',
    elementId: 'acc-information-account-setup',
  },
  {
    id: 'PAYMENT',
    title: 'Payment',
    leftIcon: 'wallet',
    rightIcon: 'check',
    isActive: false,
    route: 'ACCOUNT_SETUP',
    elementId: 'acc-setup-payment-account-setup',
  },
  {
    id: 'ADD_USERS',
    title: 'Add Users',
    leftIcon: 'user-friends',
    rightIcon: 'check',
    isSolidLeftIcon: true,
    isActive: false,
    route: 'ACCOUNT_SETUP',
    elementId: 'acc-add-users-account-setup',
  },
  {
    id: 'PIXEL_SETUP',
    title: 'Pixel Setup',
    leftIcon: 'code',
    rightIcon: 'check',
    isActive: false,
    isDisabled: false,
    route: 'ACCOUNT_SETUP',
    elementId: 'acc-pixel-account-setup',
    subOptions: [
      {
        title: 'Pixel Code',
        route: 'PIXEL_SETUP_CODE',
        rightIcon: 'check',
        isActive: false,
      },
      {
        title: 'Pixel Installation Guide',
        route: 'PIXEL_SETUP_INSTALLATION',
        rightIcon: 'check',
        isActive: false,
      },
      {
        title: 'Pixel Verification',
        route: 'PIXEL_SETUP_VERIFICATION',
        rightIcon: 'check',
        isActive: false,
        isSolidLeftIcon: false,
      },
    ],
  },
];
export const AD_GROUP_NAV_BAR_OPTIONS = [
  {
    title: 'Ad Group Information',
    leftIcon: 'info-circle',
    rightIcon: 'check',
    pageName: NEW_CAMPAIGN_PAGES.AD_GROUP_INFO,
  },
  {
    title: 'Targeting',
    leftIcon: 'bullseye-arrow',
    rightIcon: 'check',
    pageName: NEW_CAMPAIGN_PAGES.AD_GROUP_TARGETING,
  },
  {
    title: 'Ads',
    leftIcon: 'ad',
    rightIcon: 'check',
    pageName: NEW_CAMPAIGN_PAGES.AD_GROUP_ADS,
  },
];
export const DELIVERY_PROBABILITY_OPTIONS = [
  {
    level: 'Low',
    text:
      'There is a low probability that this campaign will be able to deliver the expected impressions.',
    shortText: 'Unlikely to Deliver in Full',
    icon: 'tachometer-alt-slow',
    minScale: -99.9,
    maxScale: 20.0,
  },
  {
    level: 'Medium',
    text:
      'There is a medium probability that this campaign will be able to deliver the expected impressions.',
    shortText: 'Likely to Deliver in Full',
    icon: 'tachometer-alt-average',
    minScale: 20.01,
    maxScale: 30.0,
  },
  {
    level: 'High',
    text:
      'There is a high probability that this campaign will be able to deliver the expected impressions.',
    shortText: 'Very Likely to Deliver in Full',
    icon: 'tachometer-alt-fast',
    minScale: 30.01,
    maxScale: 99.9,
  },
];
export const CAMPAIGN_STATUS_FILTERS = {
  DRAFT: 1,
  NEW: 2,
  READY_FOR_ACTIVATION: 3,
  CAMPAIGN_LIVE: 5,
  ACTIVATED: 4,
  PAUSED: 7,
  CANCELED: 10,
  ENDED: 9,
};

export const DEFAULT_DEVICE_TYPES = [
  { deviceTypeId: 1 },
  { deviceTypeId: 2 },
  { deviceTypeId: 3 },
  { deviceTypeId: 5 },
];

export const DEFAULT_FREQUENCY_RECENCY = {
  impression: 3,
  recencyCapTimePeriod: 'Minutes',
  recencyCapValue: '30',
  timePeriod: 'Day',
};

export const DEFAULT_DAY_PARTING = {
  Mon: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  Tue: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  Wed: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  Thu: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  Fri: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  Sat: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  Sun: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
};

export const DAY_PARTING_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const DAY_PARTING_HOURS = [
  { value: 0, text: '12', subText: 'AM' },
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
  { value: 6, text: '6' },
  { value: 7, text: '7' },
  { value: 8, text: '8' },
  { value: 9, text: '9' },
  { value: 10, text: '10' },
  { value: 11, text: '11' },
  { value: 12, text: '12', subText: 'PM' },
  { value: 13, text: '1' },
  { value: 14, text: '2' },
  { value: 15, text: '3' },
  { value: 16, text: '4' },
  { value: 17, text: '5' },
  { value: 18, text: '6' },
  { value: 19, text: '7' },
  { value: 20, text: '8' },
  { value: 21, text: '9' },
  { value: 22, text: '10' },
  { value: 23, text: '11' },
];
export const SELF_SERVE_OWNER_EMAIL = 'FlipplusAM@digitalremedy.com';
export const SELF_SERVE_OWNER_ID = 779;

export const CR_LINEITEM_STATUS = [LIS_CHANGE_NEW, LIS_CHANGE_PENDING_ACTIVATION];

export const ACCOUNT_UPDATE_EMAIL_RECIPIENTS = [
  'flipplusAM@digitalremedy.com',
  'ap@digitalremedy.com',
  'billing@digitalremedy.com',
];

export const PIXEL_INSTALLATION_GUIDE_URL =
  'https://docs.google.com/document/d/1OwNqRk6pTsm-RTPRebAGITfzV_JkJDndDBhLzBwyBkM/edit?usp=sharing';

export const THOUGHTSPOT_BASE_API_URL = 'https://digitalremedy.thoughtspot.cloud';

export const ACC_FQDN_VALIDATION_REGEX = /^([a-zA-Z0-9._-])+(\.co|\.com|\.io|\.uk|\.au|\.in|\.ca|\.nz|\.jp|\.bz|\.biz|\.net|\.org|\.gov)$/;
export const ACC_SUBDOMAIN_VALIDATION_REGEX = /^[a-zA-Z0-9_-]+$/;
export const ACC_NAME_VALIDATION_REGEX = /^[a-zA-Z0-9 _-]+$/;
